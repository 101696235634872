import React, { useContext, useEffect, useState } from "react"
import { Button, IconButton, TextField, Stack, Skeleton } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { Can } from "@casl/react"

import BasicTooltip from "../../items/BasicTooltip"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import SelectField from "../../items/SelectField"
import BackdropComponent from "../../items/BackdropComponent"
import JsonSnippet from "../../items/JsonSnippet"
import TestActionStatus from "../../items/TestActionStatus"
import { AbilityContext } from "../../../helper/AbilityContext"
import { formatJsonString } from "../../../utils"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const LANGUAGES = [
  {
    name: "Python",
    value: "python3",
    example: `def main(arg1: str, arg2: str) -> dict:
    return {
        "result1": arg1,
        "result2": arg2
    }`,
    disabled: false,
  },
  {
    name: "JavaScript",
    value: "javascript",
    example: `function main({arg1, arg2}) {
    return {
        result1: arg1,
        result2: arg2
    }
}`,
    disabled: false,
  },
]

const VariableColumn = ({ canEdit, cid, column, handleDelete, handleEdit, options = [] }) => {
  return (
    <div style={{ display: "grid", gap: 8, gridTemplateColumns: "1fr 2fr 40px", marginBottom: 8 }}>
      <TextField
        disabled={!canEdit}
        error={column.key_error}
        value={column.key}
        inputProps={{ maxLength: 50 }}
        onChange={(event) => handleEdit({ key: event.target.value })}
        placeholder="Name"
      />
      <SelectField
        disabled={!canEdit}
        error={column.value_error}
        options={options.map((option) => ({ label: option, value: "${" + option + "}" }))}
        onChange={(event) => handleEdit({ value: event.target.value })}
        placeholder="Select Variable"
        value={column.value}
      />
      <div>
        {cid !== 0 && (
          <IconButton onClick={() => handleDelete(cid)}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        )}
      </div>
    </div>
  )
}
const OutputColumn = ({ canEdit, cid, column, handleDelete, handleEdit }) => {
  return (
    <div style={{ display: "grid", gap: 8, gridTemplateColumns: "1fr 2fr 40px", marginBottom: 8 }}>
      <TextField
        disabled={!canEdit}
        error={column.key_error}
        value={column.key}
        inputProps={{ maxLength: 50 }}
        onChange={(event) => handleEdit({ key: event.target.value })}
        placeholder="Name"
      />
      <TextField
        disabled
        value={column.value}
        inputProps={{ maxLength: 70 }}
        onChange={(event) => handleEdit({ key: event.target.value })}
      />
      <div>
        {cid !== 0 && (
          <IconButton onClick={() => handleDelete(cid)}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

const CodeDrawer = ({ availableVars, appliData, formData, sequence, isLoadingForm, onSubmit, setUnsaved }) => {
  const ability = useContext(AbilityContext)
  const [testMsg, setTestMsg] = useState("")
  const [testStatus, setTestStatus] = useState("")
  const [testResult, setTestResult] = useState("")
  const [codeInput, setCodeInput] = useState(LANGUAGES[0].example)
  const [isDatachanged, setIsDataChanged] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [testOpen, setTestOpen] = useState(false)
  const [testing, setTesting] = useState(false)
  const [languageInput, setLanguageInput] = useState(LANGUAGES[0].value)
  const postUrl = appliData.app.http_url.replace(/\/hook\/.*/, "/test_code_execute")
  const [variableList, setVariableList] = useState([
    { key: "arg1", value: "" },
    { key: "arg2", value: "" },
  ])
  const [testVariableList, setTestVariableList] = useState([
    { key: "arg1", value: "" },
    { key: "arg2", value: "" },
  ])
  const [outputList, setOutputList] = useState([
    { key: "result1", value: `action_${sequence}_output[‘result1’]` },
    { key: "result2", value: `action_${sequence}_output[‘result2’]` },
  ])

  const updateList = (setter, callback) => {
    setter((prevValue) => callback(prevValue))
  }

  const outputAction = {
    add: () => {
      setOutputList((prevValue) => {
        const newKey = `result${prevValue.length + 1}`
        const hasDuplicatedKey = prevValue.some((v) => v.key === newKey)

        return [
          ...prevValue,
          { key: newKey, value: `action_${sequence}_output[‘${newKey}’]`, key_error: hasDuplicatedKey },
        ]
      })
      setIsDataChanged(true)
    },
    edit: (index, input) => {
      setOutputList((prevValue) =>
        prevValue.map((item, idx) => {
          item.key_error = false
          if (idx === index) {
            const hasDuplicatedKey = prevValue.some((v) => v.key === input.key)
            return { ...input, value: `action_${sequence}_output[‘${input.key}’]`, key_error: hasDuplicatedKey }
          }
          return item
        }),
      )
      setIsDataChanged(true)
    },
    remove: (index) => {
      setOutputList((prevValue) => prevValue.filter((_, idx) => idx !== index))
      setIsDataChanged(true)
    },
  }

  const variableAction = {
    add: () => {
      updateList(setVariableList, (prevValue) => [...prevValue, { key: "", value: "" }])
      updateList(setTestVariableList, (prevValue) => [...prevValue, { key: "", value: "" }])
      setIsDataChanged(true)
    },
    edit: (index, input) => {
      const editLogic = (prevValue) =>
        prevValue.map((item, idx) => {
          if (idx === index) {
            if (typeof input.key === "string") {
              return { ...item, ...input, key_error: Boolean(!input.key) }
            } else if (typeof input.value === "string") {
              return { ...item, ...input, value_error: Boolean(!input.value) }
            }
          }
          return item
        })

      updateList(setVariableList, editLogic)
      updateList(setTestVariableList, editLogic)
      setIsDataChanged(true)
    },
    remove: (index) => {
      const removeLogic = (prevValue) => prevValue.filter((_, idx) => idx !== index)

      updateList(setVariableList, removeLogic)
      updateList(setTestVariableList, removeLogic)
      setIsDataChanged(true)
    },
  }

  const handleOpenTest = () => {
    const hasEmptyInput = variableList.some((v) => !v.key)

    if (hasEmptyInput) {
      setVariableList((prevValue) =>
        prevValue.map((item) => ({
          ...item,
          key_error: Boolean(!item.key),
          value_error: Boolean(!item.value),
        })),
      )
    } else {
      // Reset testVariableList to have empty values
      setTestVariableList(
        variableList.map((item) => ({
          key: item.key,
          value: "", // Ensure value is always empty in test mode
        })),
      )
      setTestOpen(true)
    }
  }

  const handleOnSubmit = () => {
    const hasEmptyInput = variableList.some((v) => !v.key || !v.value)

    if (hasEmptyInput) {
      setVariableList((prevValue) =>
        prevValue.map((item) => {
          return { ...item, key_error: Boolean(!item.key), value_error: Boolean(!item.value) }
        }),
      )
    } else if (isDatachanged) {
      const newVariableList = variableList.map((v) => ({ variable: v.key, value_selector: v.value }))
      const newOutputList = outputList.map((v) => ({ variable: v.key, value_selector: v.value }))
      const payload = {
        codeId: formData.id,
        componentId: formData.componentId,
        name: "Code",
        language: languageInput,
        code: codeInput,
        params: {
          input: newVariableList,
          output: newOutputList,
        },
      }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }

  const handleClose = async (event) => {
    setTestOpen(false)
    setTestMsg("")
    setTestStatus("")
    setTestResult("")
    setTestVariableList((prevList) =>
      prevList.map((item) => ({
        ...item,
        value: "",
      })),
    )
  }

  const handleRunTest = async () => {
    setTesting(true)
    setTestMsg("")
    setTestStatus("")
    setTestResult("")

    const requestPayload = {
      inputs: testVariableList.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc
      }, {}),
      outputs: { result: "arg1" },
      code: codeInput,
      language: languageInput,
    }

    try {
      const response = await fetch(`${postUrl}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestPayload),
      })
      const data = await response.json()
      if (response.ok) {
        setTestResult(data.text)
        setTestStatus("success")
        setTestMsg(`Success - Execution Time: ${parseFloat(parseFloat(data.time).toFixed(2))}s`)
      } else if (!response.ok) {
        setTestStatus("error")
        setTestMsg(data.text)
      }
    } catch (error) {
      setTestStatus("Error")
      setTestMsg("Unknown Error")
    } finally {
      setTesting(false)
    }
  }

  const handleTestVariableChange = (index, newValue) => {
    setTestVariableList((prevList) =>
      prevList.map((item, idx) => (idx === index ? { ...item, value: newValue } : item)),
    )
  }

  useEffect(() => {
    if (formData.id) {
      setLanguageInput(formData.language)
      setCodeInput(formData.code)
      setVariableList(formData.params.input.map((i) => ({ key: i.variable, value: i.value_selector })))
      setTestVariableList(formData.params.input.map((i) => ({ key: i.variable, value: "" })))
      if (formData.params_json?.output) {
        setOutputList(formData.params_json.output.map((i) => ({ key: i, value: `action_${sequence}_output[‘${i}’]` })))
      } else {
        setOutputList([])
      }
    }
  }, [formData])
  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])
  useEffect(() => {
    setIsDisabled(!variableList.length || !languageInput || !codeInput)
    setIsDataChanged(formData.code !== codeInput || formData.language !== languageInput)
  }, [variableList, languageInput, codeInput])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoadingForm} />
      </section>
      {isLoadingForm ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={250} />
          <Skeleton variant="rounded" animation="wave" height={500} />
        </Stack>
      ) : (
        <>
          <Can I="update" a="Project" ability={ability}>
            <Button
              sx={{ position: "absolute", top: "1.25rem", right: "6.2rem", zIndex: 2 }}
              onClick={handleOpenTest}
              variant="outlined"
              disabled={isDisabled || isLoadingForm}
            >
              Run Test
            </Button>
            <Button
              sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
              onClick={handleOnSubmit}
              variant="contained"
              disabled={isDisabled || isLoadingForm || !isDatachanged}
            >
              Save
            </Button>
          </Can>
          <div className={ActionDrawerStyles.main}>
            <div>
              <h4 style={{ marginBottom: 16 }}>Input Variables ({variableList.length}/5)</h4>
              <div>
                {variableList.map((item, index) => (
                  <VariableColumn
                    key={index}
                    cid={index}
                    column={item}
                    canEdit={!isLoadingForm}
                    handleDelete={variableAction.remove}
                    handleEdit={(input) => variableAction.edit(index, input)}
                    options={availableVars}
                  />
                ))}
                {variableList.length < 5 && (
                  <Button onClick={variableAction.add} className={ActionDrawerStyles.add} disabled={isLoadingForm}>
                    + Add Variable
                  </Button>
                )}
              </div>
            </div>
            <div>
              <div>
                <h4>Code</h4>
                <div>
                  <div className={ActionDrawerStyles.toolInput}>
                    <h5>Language</h5>
                    <div>
                      <SelectField
                        options={LANGUAGES.map((l) => ({ label: l.name, value: l.value, disabled: l.disabled }))}
                        value={languageInput}
                        onChange={(event) => {
                          const targetItem = LANGUAGES.find((l) => l.value === event.target.value)

                          if (targetItem) {
                            setCodeInput(targetItem.example)
                          }
                          setLanguageInput(event.target.value)
                        }}
                        disabled={isLoadingForm}
                      />
                    </div>
                  </div>
                </div>
                <div className={ActionDrawerStyles.toolInput}>
                  <h5>Custom Script</h5>
                  <div>
                    <textarea
                      value={codeInput}
                      className={ActionDrawerStyles.codeContainer}
                      onChange={(event) => {
                        setCodeInput(event.target.value)
                        setIsDataChanged(true)
                      }}
                      disabled={isLoadingForm}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <h4>
                Output
                <BasicTooltip
                  tooltip={`Ensure the output variables match the keys in your code's return dictionary, or they will be empty.`}
                />
              </h4>
              <div>
                {outputList.map((item, index) => (
                  <OutputColumn
                    key={index}
                    cid={index}
                    column={item}
                    canEdit={!isLoadingForm}
                    handleDelete={outputAction.remove}
                    handleEdit={(input) => outputAction.edit(index, input)}
                  />
                ))}
                {outputList.length < 5 && (
                  <Button onClick={outputAction.add} className={ActionDrawerStyles.add} disabled={isLoadingForm}>
                    + Add New Output Variable
                  </Button>
                )}
              </div>
            </div>
          </div>
          <BackdropComponent open={testOpen} handleBackdropClose={handleClose}>
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <h3>Test Code</h3>
              <div>
                {testVariableList.map((item, index) => (
                  <div key={index}>
                    <h5>{item.key}</h5>
                    <TextField
                      value={item.value}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => handleTestVariableChange(index, event.target.value)}
                    />
                  </div>
                ))}
              </div>
              {testResult && (
                <div style={{ textAlign: "left" }}>
                  <h4>Test Output</h4>
                  <JsonSnippet>{formatJsonString(testResult)}</JsonSnippet>
                </div>
              )}
              {testMsg && <TestActionStatus msg={testMsg} status={testStatus} />}
              <div style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose("cancel")
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleRunTest} disabled={testing}>
                  Run Test
                </Button>
              </div>
            </div>
          </BackdropComponent>
        </>
      )}
    </>
  )
}

export default CodeDrawer
